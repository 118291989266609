import React from "react"
import { graphql } from "gatsby"
import scriptLoader from 'react-async-script-loader'
import { Fade } from 'react-reveal'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Informacion = ({data, isScriptLoaded, isScriptLoadSucceed}) => {
  let mainColor = "#B45B66";
  let pageName ="Información Boda";
  let titleStyle = {
    backgroundColor: mainColor,
    color: "#ffffff",
    padding: "0.5em"
  }

  return (
    <Layout Color={mainColor} Imagen={data.coverImage.childImageSharp.fluid} Titulo={pageName}>
      <SEO title={pageName} description="Toda la información de la boda, desde el código de vestimenta hats los horarios y hoteles encuentralos aquí"/>
      <Fade bottom>
        <div className="Page__Text Info__Content">
          <h1>¡NOS CASAMOS!</h1>
          <h3 style={{
            lineHeight: "1.5"
          }}>
            Y NOS HARÁ MUY FELICES QUE NOS<br/>
            ACOMPAÑEN EN ESTE DÍA TAN ESPECIAL,<br/>
            QUE TENDRÁ LUGAR EL
          </h3>
          <h1>7 de septiembre 2019</h1>
          <p style={{
            fontFamily: "'Sacramento', cursive",
            fontSize: "2.5em",
            letterSpacing: "0px"
          }}>en el</p>
          <h1 style={{
            marginBottom: "0"
          }}>hotel casa grande</h1>
          <address>
          portal matamoros 98,<br/>
          centro histórico.<br/>
          morelia, mich.
          </address>
          <p>
            <a href="https://goo.gl/maps/QC5U9mB9kLN2" target="__blank">https://goo.gl/maps/QC5U9mB9kLN2</a>
          </p>
        </div>
      </Fade>
      
      <div className="Page__Text Iconos__Boda">
        <h4 style={titleStyle}>DRESS CODE</h4>

        <div className="responsive-container">
          <div className="size-1">
            <h5 style={{
              fontWeight: "bold",
              fontSize: "1em"
            }}>FORMAL</h5>
          </div>
          <Fade left>
            <div className="size-2">
              <h5>Mujeres</h5>
              <img srcSet={data.vestidoIcon.childImageSharp.fluid.srcSet}
                sizes={data.vestidoIcon.childImageSharp.fluid.sizes}
                src={data.vestidoIcon.childImageSharp.fluid.src} alt="Vestido" />
              <h5>VESTIDO LARGO Y TACONES</h5>
            </div>
          </Fade>
          <Fade right>
            <div className="size-2">
              <h5>Hombres</h5>
              <img srcSet={data.trajeIcon.childImageSharp.fluid.srcSet}
                sizes={data.trajeIcon.childImageSharp.fluid.sizes}
                src={data.trajeIcon.childImageSharp.fluid.src} alt="Traje" />
              <h5>TRAJE Y CORBATA/MOÑO</h5>
            </div>
          </Fade>
        </div>

        <h4 style={titleStyle}>Hoteles y Tarifas Preferenciales</h4>

        <div className="responsive-container">
          <Fade left>
            <div className="size-2">
              <a href="https://hoteldelasoledad.com/" target="__blank"><img srcSet={data.soledadLogo.childImageSharp.fluid.srcSet}
                sizes={data.soledadLogo.childImageSharp.fluid.sizes}
                src={data.soledadLogo.childImageSharp.fluid.src} alt="Logo Soledad" />
              <h6>Hotel de la Soledad</h6></a>
              <a href="https://res.cloudinary.com/rafaremo/image/upload/v1558072968/assets-rafa-mon/HOTEL_DE_LA_SOLEDAD_RAFA_Y_MON.pdf" target="__blank"><h5>Descarga Info Completa<br/><small>(Tarifas, ¿Que Incluye?, etc..)</small></h5></a>
            </div>
          </Fade>
          <Fade right>
            <div className="size-2">
              <a href="https://www.canteradiezhotel.com/" target="__blank"><img srcSet={data.canteraLogo.childImageSharp.fluid.srcSet}
                sizes={data.canteraLogo.childImageSharp.fluid.sizes}
                src={data.canteraLogo.childImageSharp.fluid.src} alt="Logo Cantera 10" />
              <h6>Hotel Cantera 10</h6></a>
              <a href="https://res.cloudinary.com/rafaremo/image/upload/v1558072126/assets-rafa-mon/CANTERA_10_RAFA_Y_MON_BIEN.pdf" target="__blank"><h5>Descarga Info Completa<br/><small>(Tarifas, ¿Que Incluye?, etc..)</small></h5></a>
            </div>
          </Fade>
          <h5 className="size-1">CÓDIGO PARA TARIFA PREFERENCIAL:<br/>BODA MÓNICA Y RAFAEL</h5>
          <p className="size-1"><small>Si prefieres otro hotel o un Airbnb, recomendamos que sea cerca del centro de Morelia, ya que la boda se realizará ahí.</small></p>
        </div>

        <h4 style={titleStyle}>Orden de Eventos</h4>
        <div className="responsive-container">
          <Fade bottom>
            <div className="size-3">
                <img srcSet={data.llegadaIcon.childImageSharp.fluid.srcSet}
                  sizes={data.llegadaIcon.childImageSharp.fluid.sizes}
                  src={data.llegadaIcon.childImageSharp.fluid.src} alt="Llegada" />
                <h5>3:30 p.m.</h5>
                <h5>LLEGADA DE INVITADOS</h5>
              </div>
          </Fade>
          <Fade bottom>
            <div className="size-3">
              <img srcSet={data.accionIcon.childImageSharp.fluid.srcSet}
                sizes={data.accionIcon.childImageSharp.fluid.sizes}
                src={data.accionIcon.childImageSharp.fluid.src} alt="Acción de Gracias" />
              <h5>4:00 p.m.</h5>
              <h5>ACCIÓN DE GRACIAS Y MATRIMONIO</h5>
            </div>
          </Fade>
          <Fade bottom>
            <div className="size-3">
              <img srcSet={data.coctelIcon.childImageSharp.fluid.srcSet}
                sizes={data.coctelIcon.childImageSharp.fluid.sizes}
                src={data.coctelIcon.childImageSharp.fluid.src} alt="Cocktail" />
              <h5>5:15 p.m.</h5>
              <h5>COCKTAIL DE BIENVENIDA Y FOTOS</h5>
            </div>
          </Fade>
          <Fade bottom>
            <div className="size-2">
              <img srcSet={data.comidalIcon.childImageSharp.fluid.srcSet}
                sizes={data.comidalIcon.childImageSharp.fluid.sizes}
                src={data.comidalIcon.childImageSharp.fluid.src} alt="Cena" />
              <h5>6:00 p.m.</h5>
              <h5>CENA</h5>
            </div>
          </Fade>
          <Fade bottom>
            <div className="size-2">
              <img srcSet={data.baileIcon.childImageSharp.fluid.srcSet}
                sizes={data.baileIcon.childImageSharp.fluid.sizes}
                src={data.baileIcon.childImageSharp.fluid.src} alt="Fiesta" />
              <h5>8:00 p.m.</h5>
              <h5>FIESTA</h5>
            </div>
          </Fade>
          <Fade bottom>
            <div className="size-1">
              <img srcSet={data.finIcon.childImageSharp.fluid.srcSet}
                sizes={data.finIcon.childImageSharp.fluid.sizes}
                src={data.finIcon.childImageSharp.fluid.src} alt="Sr. y Sra. González" />
              <h5>¡SR. Y SRA. GONZÁLEZ FELICES POR SIEMPRE!</h5>
            </div>
          </Fade>
        </div>

        <h4 style={titleStyle}>Fotos</h4>

        <h1 style={{
          color: mainColor,
          margin: "5vh 0"
        }}>#RafaYMon</h1>
        <div className="Home__Hashtag-Container">
          <h4>¡¡QUEREMOS VER TUS FOTOS!!</h4>
          <br/>
          <p>
            Utiliza este hashtag para tus fotos de instagram y así compartirlas con todos los invitados. Todas los posts que se suban en instagram con este hashtag aparecerán automáticamente en nuestra galería para que las puedan ver durante y después del evento.
          </p>
          {isScriptLoaded && isScriptLoadSucceed && <div className="elfsight-app-43aef006-219b-4be3-9be2-7c92975f0842"></div>}
        </div>
      </div>
    </Layout>
  )
}

export default scriptLoader("https://apps.elfsight.com/p/platform.js")(Informacion)

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "mariposa.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    canteraLogo: file(relativePath: { eq: "cantera.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    soledadLogo: file(relativePath: { eq: "soledad.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    llegadaIcon: file(relativePath: { eq: "iconos/llegada.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accionIcon: file(relativePath: { eq: "iconos/ceremonia.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coctelIcon: file(relativePath: { eq: "iconos/coctel.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    comidalIcon: file(relativePath: { eq: "iconos/comida.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    baileIcon: file(relativePath: { eq: "iconos/baile.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    finIcon: file(relativePath: { eq: "iconos/fin.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vestidoIcon: file(relativePath: { eq: "iconos/vestido.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    trajeIcon: file(relativePath: { eq: "iconos/traje.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;